<template>
  <div>
    <base-alert>
      <h3>We are sorry, but requested {{ resource }} couldn't be found.</h3>
    </base-alert>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  components: {
    BaseAlert: () => import("@/components/shared/base/BaseAlert")
  },
  props: {
    resource: {
      type: String,
      default: "page"
    }
  },
  methods: {
    back() {
      this.$router.back();
    }
  }
};
</script>
